import React, {Component} from 'react';
import {connect} from "react-redux";

// Material UI Imports
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

// Action Imports
import {setTest} from "../../actions/home-actions";

// Logo
import Logo from '../assets/logo.png';

// Material UI Styles
const styles = theme => ({
    root: {
      justifyContent: 'center',
      flexWrap: 'wrap'
    },
    background: {
      height: 'calc(100vh - 64px)',
      widht: '100vw',
      backgroundColor: '#0D0D0D'
    },
    container: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      marginTop: 200
    }
});

class SplashPage extends Component {

    render() {
        const { classes } = this.props;

        return (
          <div className={classes.background}>
            <div className={classes.container}>
              <img  src={Logo} className={classes.logo} />
            </div>
          </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {...state,...props};
};

const mapDispatchToProps = {
    setTest
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SplashPage));
